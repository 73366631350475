<app-carrito-store></app-carrito-store>

<div class="header">
  <div class="container" style="height: 100%;">
    <div class="row align-items-center" style="height: 100%;">
      <div class="col-md-12">
        <h1>Mis pedidos</h1>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-2 contentall" >
  <div class="containt">
    <div class="row">
      <div class="col-lg-2 col-md-2 d-none-sm"></div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-1 order-md-0 containt">
        <mat-form-field appearance="outline">
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Mi producto..." #input>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

          <!-- Position Column -->
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Producto </th>
            <td mat-cell *matCellDef="let element">
              {{element.nombre}}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estatus </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list aria-label="Fish selection">
                <mat-chip color="success" [ngClass]="setColor(element.status)" selected>{{element.status}}</mat-chip>
              </mat-chip-list>
              <!-- <mat-chip color="primary" selected>Primary fish</mat-chip> -->

              <!-- <mat-chip>{{element.status}}</mat-chip> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Aún no cuenta con pedidos.</td>
          </tr>
        </table>
      </div>
      <div class="col-lg-2 col-md-2 d-none-sm"></div>

    </div>
  </div>

</div>

<ho1a-Loading *ngIf="loading"></ho1a-Loading>
