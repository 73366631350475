<mat-dialog-title class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
        <button mat-dialog-close class="btn btn-cancelar btn-sm rounded mt-3" style="width: 35px; height: 35px; padding-left: 0px;
        padding-right: 0px;" #buttonClose>
            <i class="fas fa-window-close fa-lg"></i>
        </button>
    </div>
</mat-dialog-title>
<mat-dialog-content class="row">
    <h2>
        <div class="w-100">
            {{titulo}}
        </div>
    </h2>
    <form [formGroup]="formulario" novalidate>
        <label class="col-md-12" for="puesto">Puesto</label>
        <input type="text" class="form-control" id="puesto" formControlName="puesto" readonly="readonly">

        <label class="col-md-12" for="jerarquia">Jerarquía</label>
        <input class="form-control" id="jerarquia" formControlName="jerarquia" readonly="readonly">
    
        <!-- <label class="col-md-12" for="nivel">Nivel</label>
        <div>
            <input class="form-control" id="nivel" formControlName="nivel">
            <i class="fas fa-pencil-alt"></i>
        </div>
        <div *ngIf="formulario.controls['nivel'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['nivel'].errors.required">
                El campo es requerido.
            </div>
        </div> -->
        <label class="col-md-12" for="nivel">Nivel</label> 
        <div>
            <select class="form-control" id="nivel" formControlName="nivel">
                <option>--Selecciona--</option>
                <option *ngFor="let nivel of niveles"
                    [selected]="nivel.descripcion === nivelSeleccionado">
                    {{nivel.descripcion}}
                </option>
            </select>
            <i class="fas fa-pencil-alt" *ngIf="tipo"></i>
        </div>
        <div *ngIf="formulario.controls['nivel'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['nivel'].errors.required">
                El campo es requerido.
            </div>
        </div>
        <div *ngIf="formulario.controls['nivel'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['nivel'].errors.notAllow">
                Selecciona una opción correcta.
            </div>
        </div>

        <label class="col-md-12" for="puntos">Puntos</label>
        <div>
            <input type="number" class="form-control" id="puntos" formControlName="puntos">
            <i class="fas fa-pencil-alt"></i>
        </div>
        <div *ngIf="formulario.controls['puntos'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['puntos'].errors.required">
                El campo es requerido.
            </div>
            <div *ngIf="formulario.controls['puntos'].errors.min">
                El número mínimo es 1.
            </div>
        </div>
        <div *ngIf="formulario.controls['puntos'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['puntos'].errors.pattern">
                Sólo números.
            </div>
        </div>

    </form>

    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!enviado">
    <!-- <button mat-button mat-dialog-close class="btn btn-secondary" #buttonClose>Cancelar</button>
    <button mat-button cdkFocusInitial class="btn btn-success ml-3" #buttonSend (click)="guardar()">Guardar</button> -->
    <button cdkFocusInitial class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
    style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="guardar()"><i
        class="fas fa-save ml-0 mt-0 fa-lg"></i>
    Guardar</button>
</mat-dialog-actions>