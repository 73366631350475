
<mat-dialog-title>
    <h2 mat-dialog-title *ngIf="data.tipo == 0">Rechazar reconocimiento por competencia</h2>
    <h2 mat-dialog-title *ngIf="data.tipo == 2">Aprobar Puntos Concepto</h2>
    <h2 mat-dialog-title *ngIf="data.tipo == 3">Rechazar Puntos Concepto</h2>
    <h2 mat-dialog-title *ngIf="data.tipo == 1">Aprobar reconocimiento por competencia</h2>
</mat-dialog-title>


<mat-dialog-content *ngIf="data.tipo == 0 || data.tipo == 3">
    <form [formGroup]="formulario" novalidate>
            <label class="col-md-12" for="observaciones">Justificación de Rechazo</label>
            <textarea mat-input class="form-control" id="observaciones" placeholder="Ejemplo: No se justifica el reconocimiento con información cuantitativa"  [(ngModel)]="observacionEscrita"
            formControlName="observaciones"></textarea>
            <div *ngIf="formulario.controls['observaciones'].invalid" class="invalid-feedback">
                <div *ngIf="formulario.controls['observaciones'].errors.required">
                    El campo es requerido.
                </div>
                <div *ngIf="formulario.controls['observaciones'].errors.maxlength">
                    El texto es muy largo.
                </div>
            </div>
    </form>
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.tipo == 1 || data.tipo == 2" class="text-center">
    {{data.tipo == 1 ? '¿Estás seguro de aprobar el reconocimiento por competencia?' : '¿Estás seguro de aprobar los puntos por concepto?'}}
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!alertSucces">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button cdkFocusInitial class="btn btn-rechazar ml-1 mr-1 mt-1 mb-1"
        style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="rechazar(buttonSend, buttonClose)" *ngIf="data.tipo == 0 || data.tipo == 3"><i
        class="fa fa-times ml-0 mt-1 fa-lg"></i>
        Rechazar</button>
    <button cdkFocusInitial class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
    style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="aceptar(buttonSend, buttonClose)" *ngIf="data.tipo == 1 || data.tipo == 2"><i
        class="fa fa-check ml-0 mt-1 fa-lg"></i>
        Aprobar</button>


    <!-- <button (click)="btnCancelar()" class="btn btn-secondary" #buttonClose>Cancelar</button> -->
    <!-- <button mat-button cdkFocusInitial class="btn btn-danger ml-3" #buttonSend (click)="rechazar(buttonSend, buttonClose)" *ngIf="!data.tipo">Rechazar</button>
    <button mat-button cdkFocusInitial class="btn btn-success ml-3" #buttonSend (click)="aceptar(buttonSend, buttonClose)" *ngIf="data.tipo">Aprobar</button> -->
</mat-dialog-actions>
