<app-carrito-store></app-carrito-store>
<div class="header">
    <div class="container" style="height: 100%;">
        <div class="row align-items-center" style="height: 100%;">
            <div class="col-md-12">
                <h1>Mis {{ accion_plural }}</h1>
                <p class="descripcion p-0">Aquí ver podrás ver los <span style="text-transform: lowercase; color: #03496e"> {{ accion_plural }}</span> que has recibido</p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-principal contentall">
  <div class="">
    <div class="row ">
      <!-- CONTENT -->
      <div class="col-lg-2 col-md-2 d-none-sm"></div>
      <div class="col-lg-8 col-md-8 col-sm-1  2 col-xs-12 order-1 order-md-0 containt">
          <div class="row">
              <div class="col-md-12">
                  <h1 class="mt-3 mb-3 pb-3">Mis Reconocimientos por competencia</h1>
              </div>
              <div class="col-md-12 text-center mb-2" *ngIf="competencia_activa != ''">
                  <button type="button" class="btn btn-dark borrar-seleccion" (click)="borrarFiltro()">X Borrar
                      selección</button>
              </div>
              <div class="col-md-12 competencias">
                  <div class="row justify-content-center">
                      <div class="col" *ngFor="let competencia of competencias" style="margin-right: 50px!important">
                          <ho1a-BloqueCompetencias [competencia]="competencia"
                              [competencia_activa]="competencia_activa" (clickCompetencia)="filtrarDatos($event)">
                          </ho1a-BloqueCompetencias>
                      </div>
                  </div>
              </div>
              <div class="col-md-12 personas">
                  <div class="row">
                      <div class="col-md-12 mb-2" *ngFor="let persona of filtroPersonas">
                          <ho1a-BloquePersonas [persona]="persona" [type]="'mis-reconocimientos'"></ho1a-BloquePersonas>
                      </div>
                      <div *ngIf="filtroPersonas?.length == 0 && competencia_activa != '' && buscado"
                          class="col-md-12 text-center">
                          <div class="alert alert-info" role="alert">No hay resultados.</div>
                      </div>
                      <div *ngIf="competencia_activa == '' && competencias !== undefined && competencias.length > 0"
                          class="col-md-12 text-center">
                          <div class="alert alert-secondary" style="background-color: #fff;" role="alert">Selecciona una competencia para ver tu reconocimiento.</div>
                      </div>
                      <div *ngIf="competencias === undefined || competencias.length == 0"
                          class="col-md-12 text-center">
                          <div class="alert alert-secondary" role="alert">Aún no tienes ningún reconocimiento
                              recibido.</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-lg-2 col-md-2 d-none-sm"></div>

  </div>
    <div class="row">
    <!-- CONTENT -->
    <div class="col-lg-2 col-md-2 d-none-sm"></div>
    <div class="col-lg-8 col-md-8 col-sm-1  2 col-xs-12 order-1 order-md-0 containt">
        <div class="row">
            <div class="col-md-12">
                <h1 class="mt-3 mb-3 pb-3">Mis <span style="text-transform: lowercase">{{ accion_plural }} por concepto</span></h1>
            </div>
            <div class="col-md-12 text-center mb-2" *ngIf="detailReconocimientos && detailReconocimientos.length > 0">
                <button type="button" class="btn btn-dark borrar-seleccion" (click)="borrarFiltroReconocimientos()">X Borrar
                    selección</button>
            </div>
            <div class="col-md-12 competencias">
                <div class="row justify-content-center">
                    <div class="col" *ngFor="let r of reconocimientos">
                        <div class="card space-between default " (click)="filtrarConceptos(r)" [style.background-color]="'#DD771C'">
                          <span class="text-center">{{r.total}}</span>
                          <span class="text-center" style="font-size: 11px;">{{r.tipo}}</span>
                          <img src="assets/img/fi-ss-trophy.svg" class="img-fluid" width="100%" height="5%">
                          <span class="text-center">{{ r.puntos }} {{ accion_plural }}.</span>
                        </div>
                      <!-- <div class="card space-between">
                        <span class="text-center" id="concepto">{{r.evento}}</span>
                        <span class="text-justify mt-3">{{r.concepto}}</span>
                        <span id="number" class="text-center bold mt-2">{{ r.puntos + ' Válvulas' }}</span>
                        <img src="../../assets/img/bytecoin/currency-byte.png" alt="">
                      </div> -->
                    </div>
                </div>

                <div class="col-md-12 mb-2 mt-4" *ngFor="let r of detailReconocimientos">
                  <ho1a-BloquePersonas [persona]="r" [type]="'mis-reconocimientos'">
                  </ho1a-BloquePersonas>
                </div>

                <div *ngIf="reconocimientos.length == 0"
                class="col-md-12 text-center">
                <div class="alert alert-secondary" role="alert">Aún no tienes ningún reconocimiento
                    recibido.</div>
              </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 d-none-sm"></div>

</div>
  </div>

</div>
<ho1a-Loading *ngIf="(competencia_activa != '' && filtroPersonas?.length == 0) || loading"></ho1a-Loading>
