import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Producto } from 'src/app/shared/Models/producto';
import { ConfigService } from '../../services/config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-store-product-grid',
  templateUrl: './store-product-grid.component.html',
  styleUrls: ['./store-product-grid.component.scss']
})
export class StoreProductGridComponent implements OnInit {
  @Input() producto: Producto;
  valores: any[] = [];
  moneda: any;
  filespath = environment.filespath;
  user: any = {
    Nombre: '',
    Id: 0,
    Foto: ''
  };
  constructor(
    private authService: AuthService,
    private configService: ConfigService
  ) {
    this.user = this.authService.getCookieUser();
    this.configService.getConfig(this.user.AppId).subscribe((r: any) => {
      this.valores = r;
      let data = this.valores.find(obj => { return obj.key === 'moneda' })
      this.moneda = this.filespath + data.value;
      //console.log(this.valores)
    });
  }

  ngOnInit(): void {
  }
  /**
   * PONER BOTON EL OTRO COLOR Y "SIN STOCK" cuando no haya stock
   */

}
