<div class="container mt-3" *ngIf="accesoAdmin">
    <div class="row">
        <div class="col-md-12">
            <ul class="nav nav-pills nav-fill">
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:
                        true}" routerLink="/admin-store">Autorizar Pedidos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:
                        true}" routerLink="/admin-store/categorias">Categorias</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:
                        true}" routerLink="/admin-store/productos">Productos</a>
                </li>

            </ul>
        </div>
    </div>
</div>
