<mat-dialog-title class="row">
    {{titulo}}
</mat-dialog-title>
<mat-dialog-content class="row">
    <h2>
        <div class="w-100">
            {{titulo}}
        </div>
    </h2>
    <form [formGroup]="formulario" novalidate>
        <label class="col-md-12" for="uen">UEN</label>
        <input type="text" class="form-control" id="uen" formControlName="uen" readonly="readonly">

        <label class="col-md-12" for="area">Área</label>
        <input class="form-control" id="area" formControlName="area" readonly="readonly">

        <label class="col-md-12" for="region">Región</label>
        <input class="form-control" id="region" formControlName="region" readonly="readonly">

        <label class="col-md-12" for="sistema">Sistema</label>
        <input class="form-control" id="sistema" formControlName="sistema" readonly="readonly">

        <label class="col-md-12" for="autorizador">Autorizador</label> 
        <div>
            <select class="form-control" id="autorizador" formControlName="autorizador">
                <option *ngFor="let autorizador of autorizadoresDistintos"
                    [selected]="autorizador.nombreAutorizador === nombreSeleccionado">
                    {{autorizador.nombreAutorizador}}
                </option>
            </select>
            <i class="fas fa-pencil-alt"></i>
        </div>
        <div *ngIf="formulario.controls['autorizador'].invalid" class="invalid-feedback">
            <div *ngIf="formulario.controls['autorizador'].errors.required">
                El campo es requerido.
            </div>
        </div>
    </form>

    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!enviado">
    <!-- <button mat-button mat-dialog-close class="btn btn-secondary" #buttonClose>Cancelar</button> -->
    <!-- <button mat-button cdkFocusInitial class="btn btn-success ml-3" #buttonSend (click)="guardar()">Guardar</button> -->
    <button cdkFocusInitial class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
        style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="guardar()"><i
            class="fas fa-save ml-0 mt-0 fa-lg"></i>
        Guardar</button>
</mat-dialog-actions>