<div class="container-fluid bg-principal">
    <div class="row">
        <!-- <div class="col-md-12" style="text-align: center; color: #539BC1;">
            <h4 style="text-align: center; color: #539BC1;">Administración de Roles y Usuarios</h4>
        </div> -->
        <div class="col-md-12 mt-3">
            <h2>Administración de Roles y Usuarios</h2>
        </div>
        <div class="row col-md-12">
            <div class="col-md-12">
                <h4>Roles</h4>
            </div>
            <div class="col-md-3">
                <div class="col-md-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Buscar rol</mat-label>
                        <input matInput (keyup)="applyFilterRoles($event)" placeholder="Búsqueda" #input>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <!-- <div class="col-md-12">
                    <button mat-flat-button color="primary" class="full-width" (click)="abrirAgregarRol(1)"><i
                            class="fa fa-plus-circle mr-1 fa-lg"></i>
                        Agregar rol</button>
                </div> -->
            </div>
            <div class="col-md-9">
                <div style="overflow-x: auto;">
                    <div class="mat-elevation-z8" style="min-width: 950px;">
                        <table mat-table [dataSource]="dataSourceRoles">
                            <ng-container matColumnDef="nombre">
                                <th style="width: 20%;" mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td style="width: 20%;" mat-cell *matCellDef="let roles"> {{roles.nombre}} </td>
                            </ng-container>
                            <ng-container matColumnDef="descripcion">
                                <th style="width: 35%;" mat-header-cell *matHeaderCellDef> Descripción </th>
                                <td style="width: 35%;" mat-cell *matCellDef="let roles">
                                    {{roles.descripcion}} </td>
                            </ng-container>
                            <ng-container matColumnDef="activoDesc">
                                <th style="width: 15%; text-align: center;" mat-header-cell *matHeaderCellDef> Activo
                                </th>
                                <td style="width: 15%; text-align: center;" mat-cell *matCellDef="let roles">
                                    {{roles.activoDesc}} </td>
                            </ng-container>
                            <ng-container matColumnDef="accion">
                                <th style="width: 30%;" mat-header-cell *matHeaderCellDef> </th>
                                <td style="width: 30%;" mat-cell *matCellDef="let roles">
                                    <div id="button-div">
                                        <button type="button" class="btn btn-default rounded full-width"
                                            style="color: white; height: 35px;"
                                            (click)="abrirEditarRol(roles.nombre, roles.descripcion, roles.activo, roles.id)">
                                            <i class="fas fa-pencil-alt fa-lg"></i>
                                            Editar
                                            <!-- <div id="text-EditarRol">Editar rol</div> -->
                                        </button>
                                        <!-- <button *ngIf=" roles.nombre != 'Administrador'" type="button" class="btn btn-default rounded middle-width"
                                            style="color: white; height: 35px;"
                                            (click)="abrirEditarRol(roles.nombre, roles.descripcion, roles.esAutorizador, roles.id)">
                                            <i class="fas fa-pencil-alt fa-lg"></i>
                                            Editar
                                            <div id="text-EditarRol">Editar rol</div>
                                        </button>
                                        <button *ngIf=" roles.nombre != 'Administrador'" type="button" class="btn btn-default rounded cancel middle-width"
                                            style="color: white; height: 35px;" (click)="borrarRol(roles.id)">
                                            <i class="fa fa-trash-o fa-lg"></i>
                                            Eliminar
                                            <div id="text-borrarRol">Eliminar rol</div>
                                        </button> -->
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
                            <tr mat-row *matRowDef="let roles; columns: displayedColumnsRoles;" class="element-row">
                            </tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron resultados.</td>
                            </tr>
                        </table>
                        <div class="loading-spinner" *ngIf="loadingRoles">
                            <div id="bg-loading"></div>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row col-md-12">
            <div class="col-md-12">
                <h4>Roles de usuarios</h4>
            </div>
            <div class="col-md-3">
                <div class="col-md-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Buscar usuario</mat-label>
                        <input matInput (keyup)="applyFilterUsuarios($event)" placeholder="Búsqueda" #input>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <button type="button" class="btn btn-default rounded full-width btn-animation mb-2"
                        style="color: white; height: 35px;" (click)="abrirAgregarUsuario(1)">
                        <i class="fa fa-plus-circle mr-1 fa-lg"></i>
                        Agregar rol a usuario
                    </button>
                </div>
            </div>
            <div class="col-md-9">
                <div style="overflow-x: auto;">
                    <div class="mat-elevation-z8" style="min-width: 950px;">
                        <table mat-table [dataSource]="dataSourceUsuarios" matSort>
                            <ng-container matColumnDef="nombre">
                                <th style="width: 40%;" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td style="width: 40%;" mat-cell *matCellDef="let usuarios"> {{usuarios.nombre}} </td>
                            </ng-container>
                            <ng-container matColumnDef="rol">
                                <th style="width: 20%;" mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                                <td style="width: 20%;" mat-cell *matCellDef="let usuarios"> {{usuarios.rol}} </td>
                            </ng-container>
                            <ng-container matColumnDef="activo">
                                <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header> Activo </th>
                                <td style="width: 10%;" mat-cell *matCellDef="let usuarios"> {{usuarios.activoDesc}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="accion">
                                <th style="width: 30%;" mat-header-cell *matHeaderCellDef> </th>
                                <td style="width: 30%;" mat-cell *matCellDef="let usuarios">
                                    <div id="button-div">
                                        <button type="button" class="btn btn-default rounded middle-width"
                                            style="color: white; height: 35px;"
                                            (click)="abrirEditarUsuario(usuarios.nombre, usuarios.id_rol, usuarios.id_empleado, usuarios.id, usuarios.activo)">
                                            <i class="fas fa-pencil-alt fa-lg"></i>
                                            Editar
                                            <!-- <div id="text-EditarUsuario">Editar usuario</div> -->
                                        </button>
                                        <button type="button" class="btn btn-default rounded cancel middle-width"
                                            style="color: white; height: 35px;" (click)="borrarUsuario(usuarios.id)">
                                            <i class="fa fa-trash-o fa-lg"></i>
                                            Eliminar
                                            <!-- <div id="text-borrarUsuario">Eliminar usuario</div> -->
                                        </button>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsUsuarios"></tr>
                            <tr mat-row *matRowDef="let usuarios; columns: displayedColumnsUsuarios;"
                                class="element-row">
                            </tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No se encontraron resultados.</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]"></mat-paginator>
                        <div class="loading-spinner" *ngIf="loadingUsuarios">
                            <div id="bg-loading"></div>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ho1a-Loading *ngIf="loading"></ho1a-Loading>