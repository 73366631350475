import { environment } from './../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import  {RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';

const APIItGov = environment.APIItGov;

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

constructor(private http: HttpClient) {
}

getConfig(appId: number) {
  return this.http.get(`${APIItGov}/ApplicationsConfigurations/5`);
}

getConfigurations() {
  return this.http.get<any[]>(`${APIItGov}/configuration`);
}

}
