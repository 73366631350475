import { Component, OnInit } from '@angular/core';
import {ReconocimientosService} from '../services/reconocimientos.service';
import {ConfigService} from '../services/config.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  link = {
    value: ''
  };
  ruta: any;
  constructor(
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.configService.getConfigurations().subscribe(r => {
      console.log(r)
        this.link = r.find(x => x.property === 'link');
      }
    );
  }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
