import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TokenService } from "../../services/token.service";
import {ColaboradoresService} from '../../services/colaboradores.service';
import {AuthService} from '../../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
      private tokenService: TokenService,
      private colaboradorService: ColaboradoresService,
      private authService: AuthService,
    ) {
      // console.log("ENV_cookieUser");
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenService.getToken();
        const company = localStorage.getItem('id_compania');
        // if (!token) {
            // return next.handle(req);
        // }

        const headers = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`).set('companyId', company)
        });
        return next.handle(headers);
    }

}
