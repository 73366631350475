<app-carrito-store></app-carrito-store>
<div class="header">
  <div class="container" style="height: 100%;">
    <div class="row align-items-center" style="height: 100%;">
      <div class="col-md-12">
        <h1>Reconocimientos</h1>
        <p class="descripcion m-0">Reconoce a tus compañeros por vivir las competencias como parte de la cultura de la empresa</p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-principal contentall">
  <div class="row">
    <div class="col-lg-2 col-md-2 d-none-sm"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <div class="col-md-12 boton-reconocer">
        <div class="col-md-12 mb-2">
          <a class="btn btn-large reconocer" routerLink='enviar-reconocimiento'>Otorgar Reconocimientos</a>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 d-none-sm"></div>
  </div>
  <div class="row">
    <div class="col-lg-2 col-md-2 d-none-sm"></div>
    <div class="col-lg-8 col-md-8 col-sm-1  2 col-xs-12 order-1 order-md-0 containt">
      <div class="row">
        <div class="col-md-12">
          <h1 class="mt-3 mb-3 pb-3">Mis Reconocimientos otorgados</h1>
        </div>
        <div class="col-md-12 competencias">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center mb-2" *ngIf="competencia_activa != ''">
              <button type="button" class="btn btn-dark borrar-seleccion" (click)="borrarFiltro()">X Borrar
                selección</button>
            </div>
            <div class="col" *ngFor="let competencia of competencias" style="margin-right: 50px!important">
              <ho1a-BloqueCompetencias [competencia]="competencia" [competencia_activa]="competencia_activa" (blur)="blurEvent()"
                (clickCompetencia)="filtrarDatos($event)">
              </ho1a-BloqueCompetencias>
            </div>
          </div>
        </div>
        <div class="col-md-12 personas">
          <div class="row">
            <div class="col-md-12 mb-2" *ngFor="let persona of filtroPersonas">
              <ho1a-BloquePersonas [persona]="persona" [type]="'reconocer-a-otros'"></ho1a-BloquePersonas>
            </div>
            <div *ngIf="filtroPersonas?.length == 0 && competencia_activa != '' && buscado"
              class="col-md-12 text-center">
              <div class="alert alert-info" role="alert">No hay resultados.</div>
            </div>
            <div *ngIf="competencia_activa == '' && competencias !== undefined && competencias.length > 0"
              class="col-md-12 text-center">
              <div class="alert alert-secondary" style="background-color: #fff;" role="alert">Selecciona una competencia para ver a quiénes
                has reconocido.</div>
            </div>
            <div *ngIf="competencias === undefined || competencias.length == 0" class="col-md-12 text-center">
              <div class="alert alert-secondary" role="alert">Aún no tienes ningún reconocimiento otorgado.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 d-none-sm"></div>

  </div>
</div>

<ho1a-Loading *ngIf="(competencia_activa != '' && filtroPersonas?.length == 0) || loading"></ho1a-Loading>
