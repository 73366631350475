<app-carrito-store></app-carrito-store>

<div class="header">
  <div class="container" style="height: 100%;">
    <div class="row align-items-center" style="height: 100%;">
      <div class="col-md-12">
        <h1>Reconocer a otros Colaboradores</h1>
        <p class="descripcion m-0">Aquí podrás reconocer a otros Colaboradores a través de un reconocimiento por alguna
          competencia, recuerda ser muy objetivo en tu reconocimiento.
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-principal contentall">
  <div class="row justify-content-end">
    <!-- SIDEBAR -->

    <div class="col-md-5 sidebar pr-5 pl-5">
      <div class="row puntos-disponibles mt-5">
        <div class="col-lg-9 pt-4 pb-4 my-auto">
          <span class="title">Reconocimientos disponibles</span>
        </div>
        <div class="col-lg-3 text-center pt-4 pb-4 puntos pl-0 pr-0">
          <span>{{ puntosDisponibles }}</span>
        </div>
      </div>
      <div class="row grafica mt-3 p-4">
        <div class="col-md-12">
          <div class="img">
            <img class="img-fluid"
              src="../../../assets/img/reconocer_otros/Plataforma Reconocimientos_COMERCIAL_Persona.png">
          </div>
          <p class="title">Colaborador</p>
        </div>
        <div class="col-md-12">
          <div class="col text-center simbolo">
            <p id="simbolo" class=""><i class="fas fa-times"></i></p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="img">
            <img class="img-fluid"
              src="../../../assets/img/reconocer_otros/Plataforma Reconocimientos_COMERCIAL_Situación.png">
          </div>
          <p class="title">Competencia</p>
        </div>
        <div class="col-md-12">
          <div class="col text-center simbolo">
            <p id="simbolo" class=""><i class="fas fa-equals"></i></p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="img">
            <img class="img-fluid"
              src="../../../assets/img/reconocer_otros/Plataforma Reconocimientos_COMERCIAL_Resultados.png">
          </div>
          <p class="title">Reconocimiento</p>
        </div>
      </div>
    </div>
    <!-- ./SIDEBAR -->
    <div class="col-md-7">
      <div class="row formulario ml-4 mr-4 mb-4 pb-5">
        <div class="col-md-12 text-center enviar">
          <h1>Enviar reconocimiento por competencia</h1>
        </div>
        <div *ngIf="puntosDisponibles > 0" class="col-md-12">
          <form [formGroup]="formulario" novalidate>
            <div class="form-group row">
              <label class="col-md-12 col-form-label"><i class="far fa-user"></i>
                ¿Quién?</label>
                <div *ngIf="loadingUsers" style="width: 100%;">
                  <div style="display: flex; justify-content: center;">
                    <mat-spinner [diameter]="20"></mat-spinner>
                  </div>
                </div>
              <div class="col-md-12 label-align search" *ngIf="!loadingUsers">
                <!-- <i class="fas fa-search"></i> -->
                <!-- <input  type="text" id="id_empleado_recibe" class="form-control"
                  (click)="closeAlerts()"
                  formControlName="id_empleado_recibe" matInput [(ngModel)]="empleadoSeleccionado"
                  (ngModelChange)="onEmpleadoChange($event)" [matAutocomplete]="auto"> -->
                <mat-form-field class="example-full-width" appearance="outline"
                  *ngIf="resultadoBusqueda && resultadoBusqueda.length > 0; else loading">
                  <mat-label>Colaborador</mat-label>
                  <input type="text" placeholder="Buscar..." aria-label="Number" matInput
                    [formControlName]="'id_empleado_recibe'"
                    [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option.nombreCompleto}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div *ngIf="formulario.controls['id_empleado_recibe'].invalid" class="invalid-feedback">
                  <div *ngIf="formulario.controls['id_empleado_recibe'].errors.required">
                    El campo es requerido.
                  </div>
                  <div *ngIf="formulario.controls['id_empleado_recibe'].errors.notAllow">
                    El empleado no existe.
                  </div>
                </div>
                <!-- <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngIf="resultadoBusqueda.length == 0 && buscandoEmpleados">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </mat-option>
                  <mat-option *ngFor="let resultado of resultadoBusqueda" value="{{ resultado.nombreCompleto}}">
                    <app-avatar-icon size="m" type="rounded-circle" bg_color="#00a3df" text_color="white"
                      [name]="resultado.nombreCompleto" style="display: inline-flex;"></app-avatar-icon>
                    <span>&nbsp;{{resultado.nombreCompleto}}</span>
                  </mat-option>
                </mat-autocomplete> -->
                <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let resultado of resultadoBusqueda" [value]="resultado">
                    <span>&nbsp;{{resultado.nombreCompleto}}</span>
                  </mat-option>
                </mat-autocomplete> -->

              </div>

            </div>
            <div class="form-group row">
              <label for="id_competencia" class="col-md-12 col-form-label"><i class="far fa-star"></i>
               Competencias</label>
              <div class="col-md-12 label-align competencias-list">
                <i class="fas fa-sort-down"></i>
                <select class="form-control" id="id_competencia" formControlName="id_competencia"
                  (click)="nivelDefault()">
                  <option value="-1">--Selecciona--</option>
                  <option *ngFor="let competencia of competencias" value="{{competencia.id}}">{{
                                        competencia.name }}</option>
                </select>
                <div *ngIf="formulario.controls['id_competencia'].invalid" class="invalid-feedback">
                  <div *ngIf="formulario.controls['id_competencia'].errors.notAllow">
                    Selecciona una opción.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="motivo" class="col-md-12 col-form-label"><i class="far fa-thumbs-up"></i> ¿Por
                qué lo reconoces?</label>
              <div class="col-md-12">
                <textarea class="form-control" id="motivo" formControlName="motivo"></textarea>
                <div *ngIf="formulario.controls['motivo'].invalid" class="invalid-feedback">
                  <div
                    *ngIf="formulario.controls['motivo'].errors.required || formulario.controls['motivo'].errors.vacio">
                    El campo es requerido.
                  </div>
                  <div *ngIf="formulario.controls['motivo'].errors.maxlength">
                    El texto es muy largo.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="logro" class="col-md-12 col-form-label"><i><svg aria-hidden="true" focusable="false"
                    data-prefix="far" data-icon="pencil-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512" class="svg-inline--fa fa-pencil-alt fa-w-16 fa-3x">
                    <path fill="currentColor"
                      d="M491.609 73.625l-53.861-53.839c-26.378-26.379-69.075-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM200.443 311.557C204.739 315.853 210.37 318 216 318s11.261-2.147 15.557-6.443l119.029-119.03 28.569 28.569L210 391.355V350h-48v-48h-41.356l170.259-169.155 28.569 28.569-119.03 119.029c-8.589 8.592-8.589 22.522.001 31.114zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"
                      class=""></path>
                  </svg></i> ¿Qué
                logró?</label>
              <div class="col-md-12">
                <textarea class="form-control" id="logro" formControlName="logro"></textarea>
                <div *ngIf="formulario.controls['logro'].invalid" class="invalid-feedback">
                  <div
                    *ngIf="formulario.controls['logro'].errors.required || formulario.controls['logro'].errors.vacio">
                    El campo es requerido.
                  </div>
                  <div *ngIf="formulario.controls['logro'].errors.maxlength">
                    El texto es muy largo.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 boton-enviar">
              <button class="btn btn-large" #button (click)="enviarReconocimiento(button)"
                [disabled]="!formulario.valid">Enviar</button>
            </div>
          </form>
        </div>
        <div *ngIf="puntosDisponibles <= 0" class="col-md-12 mt-3">
          <div class="alert alert-warning text-center" role="alert">
            No tienes puntos disponibles.
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<ho1a-Loading *ngIf="enviado || loading"></ho1a-Loading>
<!-- <ho1a-Loading *ngIf="resultadoBusqueda.length == 0"></ho1a-Loading> -->
