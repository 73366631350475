<div class="product-card animatedRec animate__animated">
    <a href="javascript:void(0);" [routerLink]="'/store/producto/'+producto.id">
        <div class="product-img">
            <img [src]="filespath + producto.imagen" onerror="this.src='../../../assets/img/demo/notavailable.png'" class="img-fluid">
        </div>
        <!-- <h6>{{ producto.nombre }}</h6> -->
        <div class="product-price">
            <div class="price">
                <!-- <img src="../../../assets/img/bytecoin/bananos2.png" class="img-fluid"> -->
                <img src="{{ valores.length > 0 ? moneda : '' }}" class="img-fluid">
                {{ producto.costo }}
            </div>
            <button class="btn">Canjear</button>
        </div>
    </a>
</div>
