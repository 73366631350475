import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ho1a-Loading',
    templateUrl: './Loading.component.html',
    styleUrls: ['./Loading.component.scss']
})
export class LoadingComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    
    }

}
