import { Component, OnInit } from '@angular/core';
import { UsuariosRolesService } from '../../services/usuarios-roles.service';
import { AuthService } from '../../services/auth.service';
import {ModalAdminEditarCompetencias} from '../../components/ModalAdminEditarCompenencias/ModalAdminEditarCompetencias.component';
import { PuntosService } from '../../services/puntos.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'ho1a-Admin-Menu',
    templateUrl: './Menu.component.html',
    styleUrls: ['./Menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
    user: any;
    accesoAdmin: boolean = false;
    showStoreAuth = false;
    showAckAuth = false;
    activo: Boolean;
    loading: boolean = false;
    gridData: any = [];
    error: string;

  constructor(
        private usuariosRolesService: UsuariosRolesService,
        private authService: AuthService,
        private puntosService: PuntosService,
        public dialog: MatDialog
  ){}
    ngOnInit(){
        this.user = this.authService.getCookieUser();
        this.showAckAuth = this.user.IsAdminAck == 'True' ? true : false;
        // this.showStoreAuth = this.user.IsAdminStore == 'True' ? true : false;
        //Revisar si es admin
        this.usuariosRolesService.getUsuarioRol(this.user.Id).subscribe(resp=>{
            if(resp == 1 || resp == 4){
                this.accesoAdmin = true;
            }
        });
    }

    abrirNuevo(){
    const dialogRef = this.dialog.open(ModalAdminEditarCompetencias, {
      width: '500px',
      data: {
        nombre: '',
        descripcion: '',
        nivel: '',
        img: '',
        id: '',
        tipo: 2,
        usuarios: []
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.loading = true;
      // this.getAllConceptosPuntos();
    });
  }

    getAllConceptosPuntos() {
    this.activo = true;
    this.loading = true;
    this.puntosService.obtenerPuntosAsignadosPorConceptos().subscribe(resp =>{
        this.gridData = resp;
        this.loading = false;
      }, error => this.error = error,
      () => {}
    );
  }

}
