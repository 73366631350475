<span
    [class]="'avatar ' + size + ' ' + type"
    [style]="'background: ' + bg_color + '; color: ' + text_color"
    *ngIf="showAvatar"
    >
    <span class="avatar-ul-m" *ngIf="!showImage">
        {{ capitalizeString }}
    </span>

    <img [ngClass]="image == 'assets/img/inicio/iconos/stars.SVG' ? 'inverted' : ''" *ngIf="showImage"
      [class]="'avatar ' + size + ' ' + type" [src]="image" alt="">
</span>
