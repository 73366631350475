<div id="loading" class="loading">
    <div class="load-wrapp load-5">
        <span class="loading-text">Cargando...</span>
        <span>Espere un momento por favor.</span>
        <div class="ring-2">
            <img src="../../../assets/img/bytecoin/byte-icon-star.png">
            <div class="ball-holder">
                <div class="ball"></div>
                <div class="ball-2"></div>
            </div>
        </div>
    </div>
    <div>
        <!-- <span class="loading-text">Cargando...</span>
        <span>Espere un momento por favor.</span> -->
        <!-- <img src="../../../assets/img/bytecoin/byte-icon-star.png"> -->
    </div>
</div>