<ho1a-Admin-Menu></ho1a-Admin-Menu>

<div class="container-fluid pt-4 bg-principal">
    <div class="row">
        <div class="col-md-12">
            <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" width="100%" [filterable]="true"
            [rowClass]="rowCallback"
                [columnMenu]="true" [loading]="loading" [sortable]="{ allowUnsort: true, mode: 'multiple' }"
                [pageable]="{ buttonCount: 5, pageSizes: [5,10,20,100] }" [pageSize]="10">
                <kendo-grid-column field="enviadoPor" [title]="'Enviado por'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column field="reconoceA" [title]="'Reconocer a'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column field="competencia" [title]="'Competencias'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column field="motivo" [title]="'Motivo'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column field="logro" [title]="'Logro'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column field="autorizador" [title]="'Autorizador'" width="15%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                </kendo-grid-column>
                <kendo-grid-column [title]="'Acciones'" width="20%"
                class="statusColumn"
                    [headerStyle]="{'color': '#3C404C','line-height': '1em', 'font-weight': 'bold'}"
                    [style]="{'background-color': '#FFF','color': '#000'}"
                    [footerStyle]="{'background-color': '#0B3B59','color': '#fff'}">
                    <ng-template kendoGridCellTemplate let-dataItem class="text-align:center">
                        <div id="button-div">

                            <div *ngIf="!dataItem.activo">
                                <!-- <button mat-button  class="btn btn-success rounded btn-aprobar"

                                    (click)="aprobarConcepto(dataItem)"
                                    [disabled]="loading">
                                    <i class="fa fa-check ml-0 mt-0 fa-lg"></i> Aprobar Concepto
                                </button> -->

                                <button (click)="aprobarConcepto(dataItem)"
                                matTooltip="Aprobar concepto"
                                [disabled]="loading" mat-mini-fab color="primary" aria-label="Example icon button with a bookmark icon">
                                    <mat-icon>check</mat-icon>
                                </button>

                                <button (click)="rechazarConcepto(dataItem)"
                                matTooltip="Rechazar concepto"
                                [disabled]="loading" mat-mini-fab color="warn" aria-label="Example icon button with a bookmark icon">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <!-- <button type="button" class="btn btn-danger btn-sm ml-1 mt-1 rounded btn-rechazar"

                                    (click)="rechazarConcepto(dataItem)"
                                    [disabled]="loading">
                                    <i class="fa fa-times ml-0 mt-0 fa-lg"></i> Rechazar
                                </button> -->
                            </div>

                            <div *ngIf="dataItem.activo">

                                <button (click)="aceptar(dataItem.id, dataItem.id_empleado_envia, dataItem.id_empleado_recibe, dataItem.enviadoPor)"
                                matTooltip="Aprobar reconocimiento"
                                [disabled]="loading" mat-mini-fab color="primary" aria-label="Example icon button with a bookmark icon">
                                    <mat-icon>check</mat-icon>
                                </button>

                                <button (click)="rechazar(dataItem.id, dataItem.id_empleado_envia, dataItem.id_empleado_recibe, dataItem.reconoceA)"
                                matTooltip="Rechazar reconocimiento"
                                [disabled]="loading" elevation="0" mat-mini-fab color="warn" aria-label="Example icon button with a bookmark icon">
                                    <mat-icon>close</mat-icon>
                                </button>

                            </div>

                        </div>
                        <div id="button-div">

                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages groupPanelEmpty="Arrastre las columnas aquí para agrupar" pagerItems="registros"
                    pagerFirstPage="Primer página" pagerNextPage="Página siguiente" pagerLastPage="Última página"
                    pagerPage="Por página" pagerOf="de" pagerItemsPerPage="registros por página"
                    pagerPreviousPage="Página anterior" noRecords="No hay registros para mostrar."
                    filterContainsOperator="Contiene" filterEndsWithOperator="Termina con" filterEqOperator="Igual"
                    filterNotEqOperator="No es igual" filterNotContainsOperator="No contiene"
                    filterStartsWithOperator="Comienza con" filterIsNullOperator="Es nulo"
                    filterIsNotNullOperator="No es nulo" filterIsEmptyOperator="Esta vacio" filterBooleanAll="Todos"
                    filterIsTrue="Activo" filterIsFalse="Inactivo" filterIsNotEmptyOperator="No está vacío"
                    sortAscending="Ascendente" sortDescending="Descendente" filter="Filtro" columns="Columnas"
                    columnsApply="Aplicar" columnsReset="Limpiar" filterAndLogic="Y" filterOrLogic="O"
                    filterFilterButton="Filtrar" filterClearButton="Limpiar">
                </kendo-grid-messages>
            </kendo-grid>
        </div>
    </div>
</div>

<ho1a-Loading *ngIf="loading"></ho1a-Loading>
