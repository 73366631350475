<mat-dialog-title class="row">
    <div class="col-md-11"></div>
    <div class="col-md-1">
        <button type="button" mat-dialog-close class="btn btn-secundary mr-2 mt-2 mb-1 ml-5 close btn-sm rounded"
            style="width: 35px; height: 35px;" #buttonClose>
            <i class="fas fa-window-close" style="color:white"></i>
        </button>
    </div>
</mat-dialog-title>
<mat-dialog-content class="row mat-typography">
    <h2>
        <div *ngIf="data.tipo == 1" class="w-100">
            Agregar rol a usuario
        </div>
        <div *ngIf="data.tipo == 2" class="w-100">
            Editar rol de usuario
        </div>
    </h2>

    <form [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="id_empleado" [matAutocomplete]="autoColaborador"
                        (keyup)="buscarColaboradores(form.controls['id_empleado'].value)" [readonly]="isReadOnly">
                </mat-form-field>
                <div *ngIf="form_valid && form.controls['id_empleado'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="form.controls['id_empleado'].errors.required">
                        Esta opción es requerida.
                    </div>
                </div>
                <mat-autocomplete #autoColaborador="matAutocomplete">
                    <mat-option *ngFor="let resultado of resultadoBusquedaColaborador" value="{{ resultado.nombre }}"
                        (click)="setCurrentColaboradorId(resultado.id, resultado.id_Mga_PlazasMh)">
                        <span>&nbsp;{{ resultado.nombre }}</span>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Rol <i class="fas fa-pencil-alt ml-1" *ngIf="esEdicion"></i></mat-label>
                    <mat-select formControlName="id_rol">
                        <mat-option *ngFor="let roles of rolesRecividos" [value]="roles.id">
                            {{roles.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="form_valid && form.controls['id_rol'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="form.controls['id_rol'].errors.required">
                        Este campo es requerido
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="!esNuevo">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Activo <i class="fas fa-pencil-alt ml-1" *ngIf="esEdicion"></i></mat-label>
                    <mat-select formControlName="activo">
                        <mat-option *ngFor="let act of EsActivo" [value]="act.id">
                            {{act.descripcion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="form_valid && form.controls['activo'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="form.controls['activo'].errors.required">
                        Este campo es requerido
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="loading-spinner" *ngIf="loading">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="row">
    <div class="row col-md-12">
        <div class="col-md-9"></div>
        <div class="col-md-3">
            <button mat-flat-button cdkFocusInitial class="btn btn-success full-width" #buttonSend (click)="guardar()">
                <i class="fas fa-save fa-lg mb-1" style="color: #FEC526; vertical-align: center;"></i> Guardar
            </button>
        </div>
    </div>
</mat-dialog-actions>