<mat-dialog-title class="row">
    <div class="col-md-11"></div>
    <div class="col-md-1">
        <button type="button" mat-dialog-close class="btn btn-secundary mt-2 mr-2 mb-1 ml-5 close btn-sm rounded"
            style="width: 35px; height: 35px;" #buttonClose>
            <i class="fas fa-window-close" style="color:white"></i>
        </button>
    </div>
</mat-dialog-title>
<mat-dialog-content class="row">
    <h2>
        <div class="w-100">
            {{titulo}}
        </div>
    </h2>

    <form [formGroup]="formulario" novalidate>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Nombre <i class="fas fa-pencil-alt ml-1" *ngIf="esEdicion && mostrarEdicion"></i></mat-label>
                    <input matInput formControlName="nombre">
                </mat-form-field>
                <div *ngIf="form_Enviado && formulario.controls['nombre'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="formulario.controls['nombre'].errors.required">
                        El campo es requerido.
                    </div>
                    <div *ngIf="formulario.controls['nombre'].errors.maxlength">
                        El texto es muy largo.
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Descripción <i class="fas fa-pencil-alt ml-1" *ngIf="esEdicion"></i></mat-label>
                    <input matInput formControlName="descripcion">
                </mat-form-field>
                <div *ngIf="form_Enviado && formulario.controls['descripcion'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="formulario.controls['descripcion'].errors.required">
                        El campo es requerido.
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label> Activo <i class="fas fa-pencil-alt ml-1" *ngIf="esEdicion && mostrarEdicion || mostrarEdicionAut"></i></mat-label>
                    <mat-select formControlName="activo">
                        <mat-option *ngFor="let act of EsActivo" [value]="act.id">
                            {{act.descripcion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="form_Enviado && formulario.controls['activo'].invalid" class="invalid-feedback"
                    style="margin-top: -15px;margin-bottom: 15px;">
                    <div *ngIf="formulario.controls['activo'].errors.required">
                        Este campo es requerido
                    </div>
                </div>
            </div>
        </div>

    </form>
    <div class="loading-spinner" *ngIf="loading">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="row">
    <div class="row col-md-12">
        <div class="col-md-9"></div>
        <div class="col-md-3">
            <button mat-flat-button cdkFocusInitial class="btn btn-success full-width" #buttonSend (click)="guardar()">
                <i class="fas fa-save fa-lg mb-1" style="color: #FEC526; vertical-align: center;"></i> Guardar
            </button>
        </div>
    </div>
</mat-dialog-actions>