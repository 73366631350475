
<mat-dialog-title class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
        <button (click)="btnCancelar()" class="btn btn-cancelar btn-sm rounded mt-3 mb-2" style="width: 35px; height: 35px; padding-left: 0px;
        padding-right: 0px;" #buttonClose>
            <i class="fas fa-window-close fa-lg"></i>
        </button>
    </div>
</mat-dialog-title>
<h2 mat-dialog-title *ngIf="data.tipo === 0">Rechazar pedido</h2>
<h2 mat-dialog-title *ngIf="data.tipo === 1">Aprobar pedido</h2>
<h2 mat-dialog-title *ngIf="data.tipo === 3">Cambiar estatus del pedido</h2>
<h2 mat-dialog-title *ngIf="data.tipo === 4">Cambiar estatus del pedido</h2>
<h2 mat-dialog-title *ngIf="data.tipo === 4">Marcar pedido como entregado</h2>
<mat-dialog-content *ngIf="!data.tipo">
    <form [formGroup]="formulario" novalidate>
            <label class="col-md-12" for="observaciones">Observaciones Generalista de RH</label>
            <textarea class="form-control" id="observaciones" placeholder="Ejemplo: No se justifica el pedodo con información cuantitativa"  [(ngModel)]="observacionEscrita"
            formControlName="observaciones"></textarea>
            <div *ngIf="formulario.controls['observaciones'].invalid" class="invalid-feedback">
                <div *ngIf="formulario.controls['observaciones'].errors.required">
                    El campo es requerido.
                </div>
                <div *ngIf="formulario.controls['observaciones'].errors.maxlength">
                    El texto es muy largo.
                </div>
            </div>
    </form>
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.tipo !==3 && data.tipo !==5" class="text-center">
    ¿Estás seguro de aprobar el pedido?
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.tipo === 3" class="text-center">
    ¿Estás seguro de cambiar el estatus del pedido?
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.tipo === 5" class="text-center">
    ¿Estás seguro de cambiar el estatus del pedido?
    <div class="loading-spinner" *ngIf="enviado">
        <div id="bg-loading"></div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!alertSucces">
    <button cdkFocusInitial class="btn btn-rechazar ml-1 mr-1 mt-1 mb-1"
    style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="cambiar(buttonSend, buttonClose, 2)" *ngIf="data.tipo === 0"><i
        class="fa fa-times ml-0 mt-1 fa-lg"></i>
        Rechazar</button>
    <button cdkFocusInitial class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
    style="color: white; height: 35px; min-width: 110px; font-size: 14px;" #buttonSend (click)="cambiar(buttonSend, buttonClose, 1)" *ngIf="data.tipo === 1"><i
        class="fa fa-check ml-0 mt-1 fa-lg"></i>
        Aprobar</button>
    <button cdkFocusInitial
            class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
            style="color: white; height: 35px; min-width: 110px; font-size: 14px;"
            #buttonSend (click)="cambiar(buttonSend, buttonClose, 3)"
            *ngIf="data.tipo === 3">
      <i class="fa fa-check ml-0 mt-1 fa-lg"></i>Cambiar
    </button>
  <button cdkFocusInitial
            class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
            style="color: white; height: 35px; min-width: 110px; font-size: 14px;"
            #buttonSend (click)="cambiar(buttonSend, buttonClose, 4)"
            *ngIf="data.tipo === 4">
      <i class="fa fa-check ml-0 mt-1 fa-lg"></i>Cambiar
    </button>
  <button cdkFocusInitial
            class="btn btn-guardar ml-1 mr-1 mt-1 mb-1"
            style="color: white; height: 35px; min-width: 110px; font-size: 14px;"
            #buttonSend (click)="cambiar(buttonSend, buttonClose, 5)"
            *ngIf="data.tipo === 5">
      <i class="fa fa-check ml-0 mt-1 fa-lg"></i>Cambiar
    </button>
</mat-dialog-actions>
